import { FirebaseError } from "firebase/app"
import {
  AgentScreen,
  CheckoutData,
  Feedback,
  FeedbackEntity,
  LeadList,
  ModuleName,
  OutreachCampaignScreen,
  OutreachCampaignTemplate,
  Screen,
  VideoScreen,
} from "../types"
import { UsageDetails } from "../types/Usage"

export const isLocalDev = process.env.NODE_ENV === "development"

export const isDev = isLocalDev || window.location.href.includes("dev")

export const isStaging = window.location.href.includes("staging")

export const delay = (ms: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export const calculatePrice = (checkoutData: CheckoutData): number => {
  const normalAmount =
    Math.round(checkoutData.wordsCount * 0.01 * checkoutData.quantity * 100) /
    100
  return normalAmount < 0.5 ? 0.5 : normalAmount
}

export const getAvailableCredits = (usageDetails: UsageDetails): number => {
  const usedCredits = usageDetails.current_usage / 1000
  const totalCredits = usageDetails.usage_limit / 1000
  const remainingCredits = totalCredits - usedCredits
  return remainingCredits
}

export const getCampaignCharacterCount = (
  leadList: LeadList,
  campaignTemplate: OutreachCampaignTemplate,
): [number, string] => {
  const { text: template, variables } = campaignTemplate
  const { data } = leadList
  const result = data.map((e) => {
    let result = template
    variables.forEach((variable) => {
      result = result.replace(`{${variable}}`, e[variable])
    })
    return result
  })
  const count = result.join("").length
  const preview = result[0]
  return [count, preview]
}

type GetNavigationPaths = (moduleName: ModuleName) => {
  back: string
  next: string
  initial: string
}
export const getNavigationPaths: GetNavigationPaths = (moduleName) => {
  switch (moduleName) {
    case ModuleName.videos:
      return {
        back: `/`,
        next: `/${Screen.videos}/${VideoScreen.createVideoMessage}`,
        initial: "/",
      }
    case ModuleName.outreachCampaigns:
      return {
        back: `/${Screen.outreachCampaigns}`,
        next: `/${Screen.outreachCampaigns}/${OutreachCampaignScreen.createCampaignMessage}`,
        initial: `/${Screen.outreachCampaigns}`,
      }
    case ModuleName.agents:
      return {
        back: `/${Screen.myAgents}`,
        next: `/${Screen.myAgents}/${AgentScreen.agentsInfo}`,
        initial: `/${Screen.myAgents}`,
      }
  }
}

export const getFirebaseErrorMessage = (error: FirebaseError): string => {
  switch (error.code) {
    case "auth/id-token-expired":
      return "Your session has expired. Please log in again."
    case "auth/id-token-revoked":
      return "Your session has been revoked. Please log in again."
    case "auth/email-already-in-use":
      return "Email already in use. Please use login instead."
    case "auth/invalid-credential":
      return "Invalid credentials. Please check your email and password."
    case "auth/invalid-password":
      return "Invalid password. Please check your password and try again."
    case "auth/too-many-requests":
      return "You have made too many requests. Please try again later."
    case "auth/invalid-verification-code":
      return "Invalid verification code. Please check your code and try again."
    case "auth/user-disabled":
      return "Your account has been disabled. Please contact support."
    case "auth/user-not-found":
      return "User not found. Please check your email and try again."
    case "auth/invalid-action-code":
      return "Your reset password link is invalid. Please request a new one."
    default:
      return "An error occurred. Please try again."
  }
}

export const getEntitiesWithFeedback = (
  feedbacks: Feedback[],
): Set<FeedbackEntity> => {
  const entities = feedbacks
    .map((feedback) => feedback.entityType)
    .filter((e) => e != null)
  return new Set(entities)
}

export const getAgentChatUrl = (agentId: string) => {
  return isDev
    ? `https://dev.bey.chat/${agentId}`
    : isStaging
      ? `https://staging.bey.chat/${agentId}`
      : `https://bey.chat/${agentId}`
}
